import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SemkaIntervyu = () => {
  return (
    <>
      <Helmet>
        <title>Съемка интервью в Москве цена от студии Elandic</title>
        <meta name="description" content="Съемка интервью в Москве цена услуг на сайте студии Elandic. Заказать профессиональную запись видеоинтервью онлайн стоимость от видеопродакшена." />
        <meta name="keywords" content="съемка интервью, запись, видеоинтервью, москва, студия, цена" />
      </Helmet>
      <MainPage>
        <SeoText title='Съемка интервью'>
          <p>В эру цифровых технологий и социальных медиа качественное видео интервью становится мощным инструментом для коммуникации с аудиторией. Видеопродакшн Elandic в Москве предлагает услуги по съемке интервью, позволяя эффективно передать ключевые идеи и эмоции героя интервью. Благодаря индивидуальному подходу и вниманию к деталям, мы реализуем проекты, которые воздействуют на зрителей и остаются актуальными в течение долгого времени.</p>

          <p>Студия Elandic предоставляет полный спектр услуг по производству видеоконтента, включая съемку интервью. Наши специалисты обладают всем необходимым опытом и оборудованием для создания видеоматериалов высокого качества. Мы тесно сотрудничаем с клиентами на всех этапах производства - от разработки концепции до пост-продакшена - чтобы каждый элемент видео соответствовал замыслу заказчика.</p>

          <p>Стоимость съемки интервью зависит от ряда факторов: продолжительности видео, количества локаций, необходимости привлечения дополнительных профессионалов (стилистов, визажистов) и других аспектов проекта. Однако наш принцип работы – предложение оптимального сочетания цены и качества услуг, что делает съемку интервью доступной для широкого круга клиентов – от частных лиц до крупных корпораций.</p>

          <p>Студия Elandic в Москве – ваш надежный партнер в области производства видеоконтента. Мы стремимся к тому, чтобы каждая съемка интервью превосходила ожидания наших клиентов и внесла значимый вклад в их имидж. Обращаясь в студию Elandic, вы получаете не только качественный видеоконтент, но и поддержку команды профессионалов, готовых сделать ваш проект уникальным и неповторимым.</p>

        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SemkaIntervyu);
